@font-face {
  font-family: 'Defago Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/public/fonts/DefagoNotoSansLao.ttf);
}

@font-face {
  font-family: 'Defago Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/public/fonts/DefagoNotoSansLaoBold.ttf);
}

.alert {
  margin-top: 60px;
}
